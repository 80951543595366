'use strict';

angular.module('enervexSalesappApp').controller('AccountDetailCtrl', function($scope, $stateParams, Account, Auth, AccountInvitation, $q, _) {
	$scope.accountId = $stateParams.accountId;
	$scope.accountRoles = ['owner', 'member'];
	$scope.isAccountOwner = Auth.isAccountOwner;
	$scope.isAdmin = Auth.isAdmin;

	function getAccount() {
		Account.get({
			id: $stateParams.accountId
		}).$promise.then(function(res){
			$scope.account = res;
		});
	}
	getAccount()
	$scope.updateRole = function(member){
		console.log("updating role to "+member.role + " for "+ member.user.email)
		Account.update({
			id: $stateParams.accountId,
		}, {
			members: $scope.account.members
		}).$promise.then(function(account){
			console.log('succssfully updated account')
		}).catch(function(err){
			console.log('failed to update account')
		});
	}
	$scope.removeMember = function(member){
		Account.removeMember({
			id: $scope.accountId,
			userId: member.user._id
		}).$promise.then(function(res){
			$scope.account = res
		})
	}

	$scope.accountRoles = ['owner', 'member'];
	$scope.message = {};
	$scope.invites = [
		{email: '', accountRole:"member"},
		{email: '', accountRole:"member"},
		{email: '', accountRole:"member"}
	];
	$scope.sendInvites = function(){
		var newInvites = _.map($scope.invites, function(invite){
			if (invite.email !='') {
				return _.defaults({
					message: $scope.message.newMessage
				}, invite)
			}
		})
		newInvites = _.compact(newInvites)
		var missing = _.find(newInvites,function(newInvite){
			return newInvite.accountRole == "";
		})
		if (missing){
			alert("Please select a role for all invites")
			return
		}
		var promises = _.map(newInvites, function(newInvite){
			return AccountInvitation.save({
				accountId: $stateParams.accountId,
			}, newInvite).$promise
		})
		$q.all(promises).then(function(){
			$scope.invites = [
				{email: '', accountRole:"member"},
				{email: '', accountRole:"member"},
				{email: '', accountRole:"member"}
			];
			$scope.message = {};
			setTimeout(function() {
				getInvites();
				getAccount();
			},2000);
		})
	}
	$scope.removeFromTeam = function(member){
		$scope.account.members = _.filter($scope.account.members, function(m){
			return m._id != member._id
		});
		Account.update({
			id: $stateParams.accountId,
		},{
			members: $scope.account.members
		}).$promise.then(function(res){
			$scope.account = res;
		});
		$scope.removeConfirm = false;
	}
	$scope.checkEmails = function(){
		if($scope.invites[$scope.invites.length - 1].email != '') {
			$scope.invites.push({email: ''})
		}
	}
	$scope.removeInvite = function(invitation){
		AccountInvitation.remove({
			accountId: $stateParams.accountId,
			id: invitation._id
		}).$promise.then(function(res){
			getInvites()
		})
	}

	getInvites();
	function getInvites(){
		AccountInvitation.query({
			accountId: $stateParams.accountId,
			status:'pending'
		}).$promise.then(function(invites){
			$scope.internalInvites = invites;
		})
	}
});
